import React from 'react';

import { Layout } from '../../components/layout';
import SEO from '../../components/seo';
import { formatCurrency } from '../../model/format';
import { categories, Category, useHGF } from '../../model/hgf';

interface Props {
  location: { hash: string };
}

export default function HGF({ location }: Props): JSX.Element {
  const hgf = useHGF(location.hash.substring(1));
  return (
    <Layout>
      <SEO title="nerdgeschoss Human Growth Framework" />
      <div className="container">
        <nav className="breadcrumb">
          <a href="/handbook">Handbook</a>
          <span>Human Growth Framework</span>
        </nav>
        <h2>nerdgeschoss Human Growth Framework</h2>
        <h4>Why do we need this?</h4>
        <p>
          The nerdgeschoss Human Growth Framework is designed to evaluate
          capabilities and achievements across employees while trying to fairly
          decide on salaries. We try to take the guesswork out of this nebulus
          topic while trying to eliminate discriminations like the gender pay
          gap.
        </p>
        <h4>How does it work?</h4>
        <p>
          We defined several growth directions with multiple tracks each. You
          can reach levels within those tracks by regularly showing that you are
          capable of achieving the mentioned examples or comparable
          achievements. Each level is associated with points (first level gives
          1 point, second level 3 points, third level 6 points, fourth level 10
          points). Your total points then calculate your salary.
        </p>
        <p>
          The salary is calculated by a base salary (which is adjusted to the
          median salary of developers in Berlin) plus a certain amount per point
          you reached.
        </p>
        <h4>Core</h4>
        <p>
          Core skills are all about organizing yourself and the work around you.
        </p>
        <HGFCategory
          category={categories['core']}
          levels={hgf.levels['core']}
          onChange={(track, level) => {
            hgf.changeRating('core', track, level);
          }}
        />
        <h4>Engineering</h4>
        <p>Engineering skills are technical software development skills.</p>
        <HGFCategory
          category={categories['engineering']}
          levels={hgf.levels['engineering']}
          onChange={(track, level) => {
            hgf.changeRating('engineering', track, level);
          }}
        />
        <h4>Leadership</h4>
        <p>Leadership is all about helping others to achieve their best.</p>
        <HGFCategory
          category={categories['leadership']}
          levels={hgf.levels['leadership']}
          onChange={(track, level) => {
            hgf.changeRating('leadership', track, level);
          }}
        />
        <h4>Brand and Marketing</h4>
        <p>
          Brand and marketing efforts help our company to grow, both in terms of
          customers/revenue and employees.
        </p>
        <HGFCategory
          category={categories['brand']}
          levels={hgf.levels['brand']}
          onChange={(track, level) => {
            hgf.changeRating('brand', track, level);
          }}
        />
        <h4>Salary Calculation</h4>
        <p>
          You reached a total of {hgf.points}. With a base salary of{' '}
          {formatCurrency(hgf.baseSalary)} and a point salary of{' '}
          {formatCurrency(hgf.pointSalary)} this results in a total brut salary
          of
        </p>
        <div className="hgf__salary">
          {formatCurrency(hgf.salary)} per year
          <br />
          {formatCurrency((hgf.salary * 1.0) / 12.0)} per month
        </div>
      </div>
    </Layout>
  );
}

function HGFCategory({
  category,
  levels,
  onChange,
}: {
  category: Category;
  levels: Record<number, number>;
  onChange: (track: number, level: number) => void;
}): JSX.Element {
  return (
    <div className="hgf">
      <div className="hgf__category">
        {category.tracks.map((track, trackIndex) => (
          <div key={track.id} className="hgf__track">
            <div className="hgf__trackname">{track.name}</div>
            {track.levels.map((level, levelIndex) => (
              <React.Fragment key={level.level}>
                <input
                  id={`hgf-${category.id}-${track.id}-${level.level}`}
                  type="checkbox"
                  checked={levels[trackIndex] > levelIndex}
                  onChange={(event) =>
                    event.target.checked
                      ? onChange(trackIndex, levelIndex + 1)
                      : onChange(trackIndex, levelIndex)
                  }
                />

                <label
                  className="hgf__level"
                  htmlFor={`hgf-${category.id}-${track.id}-${level.level}`}
                >
                  <div className="hgf__levelname">{level.level}</div>
                  <div className="hgf__examples">
                    {level.examples.map((example) => (
                      <div key={example}>{example}</div>
                    ))}
                  </div>
                </label>
              </React.Fragment>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
