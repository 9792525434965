export function formatCurrency(
  value?: number | null,
  { maximumFractionDigits }: { maximumFractionDigits?: number } = {
    maximumFractionDigits: 2,
  }
): string | undefined {
  if (value === undefined || value === null) {
    return;
  }
  return new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits,
  }).format(value);
}
