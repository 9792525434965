import { useRef, useState } from 'react';

const categoryNames = [
  'core',
  'product_manangent',
  'engineering',
  'design',
  'leadership',
  'brand',
  'operations',
] as const;

type CategoryId = typeof categoryNames[number];

export interface Category {
  id: CategoryId;
  tracks: Track[];
}

export interface Track {
  id: string;
  name: string;
  levels: TrackLevel[];
}
export const hgfLevels = [
  'junior',
  'intermediate',
  'senior',
  'principal',
  'key person of influence',
] as const;
export type Level = typeof hgfLevels[number];

export interface TrackLevel {
  level: Level;
  examples: string[];
}

const pointMapping: Record<number, number> = {
  0: 0,
  1: 1,
  2: 3,
  3: 6,
  4: 10,
  5: 15,
};

function parseSubstring(char: string, value: string): number[] {
  const regex = `${char}(\\d)?(\\d)?(\\d)?(\\d)?`;
  return (
    value
      .match(regex)
      ?.slice(1, 5)
      ?.filter((e) => e !== undefined)
      ?.map((e) => parseInt(e)) ?? []
  );
}

function parseHgf(value: string): Record<CategoryId, number[]> {
  const values = {} as Record<CategoryId, number[]>;
  categoryNames.forEach((category) => {
    values[category] = parseSubstring(category.substring(0, 1), value);
  });
  return values;
}

export class HGFState {
  levels: Record<CategoryId, number[]>;
  private onChange: () => void;
  readonly baseSalary = 40000;
  readonly pointSalary = this.baseSalary * 0.03;

  constructor(levels: Record<CategoryId, number[]>, onChange: () => void) {
    this.levels = levels;
    this.onChange = onChange;
  }

  changeRating(category: CategoryId, track: number, level: number): void {
    this.levels[category][track] = level;
    document.location.hash = this.serialized;
    this.onChange();
  }

  get points(): number {
    return Object.values(this.levels)
      .flat()
      .reduce((sum, point) => sum + pointMapping[point], 0);
  }

  get salary(): number {
    return this.baseSalary + this.points * this.pointSalary;
  }

  get serialized(): string {
    return categoryNames
      .map((categoryName) => {
        return [categoryName.substring(0, 1), this.levels[categoryName]]
          .flat()
          .join('');
      })
      .join('');
  }
}

export function useHGF(state: string): HGFState {
  const [, setState] = useState(0);
  const hgfRef = useRef(
    new HGFState(parseHgf(state || 'c000p000e000d000l000b000o000'), () =>
      setState((state) => state + 1)
    )
  );
  return hgfRef.current;
}

export const categories: Record<CategoryId, Category> = {
  core: {
    id: 'core',
    tracks: [
      {
        id: 'self_development',
        name: 'Self Development',
        levels: [
          {
            level: 'junior',
            examples: [
              'Consistently fills out time sheets and the Daily Nerd (80%).',
              'Shows up to and is prepared for all scheduled meetings.',
              'Communicates timing issues while there is still time to fix them.',
            ],
          },
          {
            level: 'intermediate',
            examples: [
              'Consistently reaches billable hours goals.',
              'Manages working hours effectively to reach personal goals.',
            ],
          },
        ],
      },
      {
        id: 'collaboration',
        name: 'Collaboration',
        levels: [
          {
            level: 'junior',
            examples: [
              'Schedules pairing sessions for bigger features.',
              'Gives helpful feedback during code/design reviews.',
            ],
          },
          {
            level: 'intermediate',
            examples: [
              'Repeatedly plans complex stories, as a story owner, together with peers (this is NOT "who\'s gonna take the frontend task for this?").',
            ],
          },
        ],
      },
      {
        id: 'problem_solving',
        name: 'Problem Solving',
        levels: [
          {
            level: 'junior',
            examples: [
              'Fixes logic bugs within the given timebox.',
              'Reproduces and documents poorly written bug tickets from the client.',
            ],
          },
          {
            level: 'intermediate',
            examples: [
              'Proposes alternative solutions on an impact vs effort scale.',
              'Find underlying issues of bug classes and proposes a solution.',
            ],
          },
          {
            level: 'senior',
            examples: [
              'Consistently demonstrates the ability to quickly confront and tackle complex problems, even outside of their known area of expertise.',
            ],
          },
        ],
      },
    ],
  },
  product_manangent: {
    id: 'product_manangent',
    tracks: [],
  },
  engineering: {
    id: 'engineering',
    tracks: [
      {
        id: 'foundations',
        name: 'Foundations and DevOps',
        levels: [
          {
            level: 'junior',
            examples: [
              'Extends build pipelines to run additional checks.',
              'Can find issues in the current state of a system, e.g. full database or misconfigured credentials.',
              'Effectively extends configurations and capabilities of tools, e.g. improved image compression during builds.',
            ],
          },
          {
            level: 'intermediate',
            examples: [
              'Setup a new project from scratch, including review apps, build pipeline and CI.',
              'Handle Monitoring Tools.',
              'Find potential issues before they affect production systems.',
            ],
          },
          {
            level: 'senior',
            examples: [
              'Plans, evaluates and documents system architectures and services for a new project.',
              'Plans, executes and evaluates measures to increase performance of critical systems.',
            ],
          },
          {
            level: 'principal',
            examples: [
              'Intricate knowledge of common databases and how to tune them.',
            ],
          },
        ],
      },
      {
        id: 'web',
        name: 'Web',
        levels: [
          {
            level: 'junior',
            examples: [
              'Makes minor modifications to existing screens.',
              'Fixes simple design quality issues.',
              'Uses CSS and TS appropriately, following style guide.',
            ],
          },
          {
            level: 'intermediate',
            examples: [
              'Specs and builds interactive components independently.',
              'Prototypes new features quickly, e.g. a modal or popover system.',
              'Provides useful design feedback and suggests feasible alternatives before starting work on a feature.',
              'Preemptively looks for potential issues in the code base and comes up with a plan on how to deal with them.',
            ],
          },
          {
            level: 'senior',
            examples: [
              'Acts as a caretaker for all of web client code.',
              'Has intricate knowledge of frontend build tools like webpack and yarn.',
              'Designs, implements and maintains reusable libraries, e.g. the form library.',
              'Implements complex and maintainable animations across hierarchies.',
            ],
          },
          {
            level: 'principal',
            examples: [
              'Intricate knowledge of UI frameworks and how to tune performance of them.',
              'Deep understanding of application architectures and their benefits/drawbacks, can use this knowledge to plan bigger applications.',
            ],
          },
        ],
      },
      {
        id: 'servers',
        name: 'Servers',
        levels: [
          {
            level: 'junior',
            examples: [
              'Adds simple endpoints according to project structure with correct documentation and tests.',
              'Extends or updates existing endpoints with new properties.',
              'Confidently writes basic database queries for reports, including joins across tables.',
            ],
          },
          {
            level: 'intermediate',
            examples: [
              'Plans and executes more complex endpoints together with frontend developers while evaluating feature needs from a concept.',
              'Integrates third party services effectively.',
              'Makes sensible abstractions based on template and code patterns.',
            ],
          },
          {
            level: 'senior',
            examples: [
              'Can answer any project-specific question asked by peers within a reasonable research time.',
              'Creates a new application from scratch with appropriate documentation.',
              'Implements a completely new feature that is not yet covered by the application architecture, e.g. planned and implemented the billing architecture of KS.',
              'Researches existing solutions to problems and compare build vs buy solutions in terms of cost/effectiveness; makes reasonable choices and documents them.',
              'Creates reusable code between applications and maintains it as a \'gem\', as its code owner.',
            ],
          },
          {
            level: 'principal',
            examples: [
              'Deep understanding of the Rails code base and all of its frameworks.',
              'Develops complete complex database schemas and model architectures for new applications.',
            ],
          },
          {
            level: 'key person of influence',
            examples: [
              'Is a core contributor to a core system used by the company.',
            ],
          },
        ],
      },
    ],
  },
  design: {
    id: 'design',
    tracks: [],
  },
  leadership: {
    id: 'leadership',
    tracks: [
      {
        id: 'mentorship',
        name: 'Mentorship',
        levels: [
          {
            level: 'junior',
            examples: [
              'Reinforces and affirms positive feedback for good work.',
              'Teaches others about existing processes.',
              'Acts as an onboarding buddy.',
            ],
          },
          {
            level: 'intermediate',
            examples: [
              'Validates ongoing work and sustains motivation.',
              'Helps group members approach problems with curiosity.',
              'Shares interesting articles with team members to help with their growth.',
              'Asks questions to illuminate concepts, rather than stating them.',
              'Creates and shares material that transfers knowledge.',
            ],
          },
        ],
      },
      {
        id: 'direction',
        name: 'Direction',
        levels: [
          {
            level: 'junior',
            examples: [
              'Re-prioritises and consults others regarding the completion of tasks in challenging circumstances.',
              'Helps others break down problems into feasible, tangible next steps.',
            ],
          },
          {
            level: 'intermediate',
            examples: [
              'Proposes solutions when teams get bogged down or lose momentum.',
            ],
          },
          {
            level: 'senior',
            examples: ['Maintains a pulse on individual and team morale.'],
          },
        ],
      },
      {
        id: 'culture',
        name: 'Culture',
        levels: [
          {
            level: 'junior',
            examples: [
              'Actively participates and makes contributions within organizational processes.',
              'Schedules and prepares small get-togethers.',
            ],
          },
          {
            level: 'intermediate',
            examples: [
              'Engages in organizational systems thinking.',
              'Advocates for improved diversity and inclusion, and proposes ideas to help.',
              'Solves long-standing organizational problems.',
              'Organizes and conducts extensive team events.',
            ],
          },
        ],
      },
    ],
  },
  brand: {
    id: 'brand',
    tracks: [
      {
        id: 'evangelism',
        name: 'Evangelism',
        levels: [
          {
            level: 'junior',
            examples: [
              'Communicates genuine and honest excitement about their work externally.',
              'Shares personal and organizational successes with their network.',
              'Participates at relevant events.',
            ],
          },
          {
            level: 'intermediate',
            examples: [
              'Organizes positive small or medium size events that bring people to nerdgeschoss.',
              'Interacts on social media with followers of our accounts.',
              'Speaks publicly at meetups.',
            ],
          },
          {
            level: 'senior',
            examples: [
              'Builds and grows communities around topics interesting for nerdgeschoss.',
              'Is a keynote speaker at conferences.',
            ],
          },
        ],
      },
      {
        id: 'content',
        name: 'Content Creation',
        levels: [
          {
            level: 'junior',
            examples: [
              'Researches content for sharing.',
              'Is up to date on relevant industry topics and proposes them for inclusion in future posts.',
            ],
          },
          {
            level: 'intermediate',
            examples: [
              'Writes blog posts and stories for our accounts.',
              'Develops case studies.',
              'Documents events and work at nerdgeschoss.',
            ],
          },
          {
            level: 'senior',
            examples: ['Maintains and manages our monthly newsletter.'],
          },
        ],
      },
      {
        id: 'open_source',
        name: 'Open Source',
        levels: [
          {
            level: 'junior',
            examples: [
              'Contributes to existing open source projects.',
              'Documents and advertises our projects when possible.',
              'Identifies opportunities for future publications.',
            ],
          },
          {
            level: 'intermediate',
            examples: [
              'Actively maintains and supports a library on GitHub.',
              'Builds a community around the library.',
            ],
          },
        ],
      },
    ],
  },
  operations: {
    id: 'operations',
    tracks: [],
  },
};
